.card {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    flex: 0 0 auto;
    width: 180px;
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px 0 0;

    color: #000;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 400;
    line-height: 1;

    img {
      margin: 0 20px 0 0;
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    // span {
    //   width: 60px;
    // }

    .num {
      position: relative;

      .hidden {
        i {
          font-style: normal;
          opacity: 0;
        }
      }

      &__current {
        position: absolute;
        left: 0;
        text-align: right;
      }
    }
  }
  &__label {
    margin: 16px 0 0;
    color: #999;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
  }
}
