.card {
  cursor: pointer;
  flex: 0 0 calc(33.333% - 16px);
  position: relative;
  padding: 0 0 17%;
  overflow: hidden;
  transition: all 0.4s ease;

  border-radius: 12px;
  border: 1px solid #e5eaf1;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);

  @media (max-width: 767px) {
    flex: 0 0 calc(50% - 8px);
    padding: 0 0 26%;
  }
  @media (max-width: 500px) {
    flex: 0 0 calc(100%);
    padding: 0 0 53%;
  }

  &:hover {
    img {
      // transform: scale(1.1) translate(0, 4%);
      // -webkit-transform: scale(1.1) translate(0, 4%);
      object-position: bottom;
      transition: all 3s linear;
    }
    .card {
      &__name {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transition: all 0.4s ease;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);

    transition: all 1.5s linear;
  }

  &__name {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    padding: 12px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(33, 37, 41, 0.5);
    backdrop-filter: blur(3px);

    color: #fff;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    opacity: 0;

    transition: all 0.4s ease;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    @media (max-width: 991px) {
      padding: 14px 16px;
      opacity: 1;
      transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
    }

    svg {
      flex: 0 0 auto;
    }
  }
}
