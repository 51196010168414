.form {
  padding: 120px 0 0;

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }

  &Body {
    display: flex;

    padding: 30px;
    border-radius: 12px;
    border: 1px solid #e5eaf1;
    background-color: rgba($color: #fff, $alpha: 0.7);
    backdrop-filter: blur(10px);

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 20px;
    }

    input,
    textarea {
      border-radius: 0;
    }

    &__thanks {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      &Icon {
        width: 100px;
        margin: 0 0 10px;

        svg {
          width: 100%;
          height: 100%;

          // stroke: #4985ff;
          stroke: #33d133;

          transform-origin: left bottom;
          animation: svgInit 0.6s;
        }
      }

      &Title {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Helvetica;
        font-size: 48px;
        font-weight: 400;

        @media (max-width: 767px) {
          font-size: 36px;
        }
      }
      &Text {
        margin: 16px 0 0;
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        @media (max-width: 767px) {
          margin: 12px 0 0;
        }
      }
    }

    &__img {
      flex: 0 0 560px;
      border-radius: 12px;
      overflow: hidden;

      @media (max-width: 991px) {
        flex: 0 0 360px;
      }

      @media (max-width: 767px) {
        flex: 0 0 auto;
        width: 100%;
        height: 280px;
      }

      @media (max-width: 500px) {
        height: 180px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__text {
      margin: 0 0 0 130px;

      @media (max-width: 1200px) {
        margin: 0 0 0 50px;
      }

      @media (max-width: 767px) {
        margin: 24px 0 0;
      }

      &Title {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Helvetica;
        font-size: 48px;
        font-weight: 400;

        @media (max-width: 767px) {
          font-size: 36px;
        }
      }
      &Label {
        margin: 32px 0 0;
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        @media (max-width: 767px) {
          margin: 12px 0 0;
        }
      }

      &Form {
        margin: 50px 0 0;
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
          margin: 32px 0 0;
        }

        label {
          &:not(:first-child) {
            margin: 16px 0 0;
          }

          &._err {
            input {
              color: #ff3434;
              border-bottom: 1px solid #ff2424;

              &::placeholder {
                opacity: 0.7;
                color: #ff3434;
              }
            }
          }

          input {
            width: 100%;
            background-color: transparent;
            color: #212529;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;

            border-bottom: 1px solid #212529;
            padding: 0 0 12px;

            @media (max-width: 767px) {
              font-size: 14px;
              padding: 0 0 10px;
            }
          }
        }

        textarea {
          height: 100px;
          margin: 16px 0 0;
          background-color: transparent;
          color: #212529;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;

          resize: none;

          border-bottom: 1px solid #212529;
          padding: 0 0 12px;

          @media (max-width: 767px) {
            font-size: 14px;
            padding: 0 0 10px;
          }
        }

        button {
          align-self: flex-start;
          margin: 20px 0 0;
          padding: 12px 20px;
          background: #212529;

          display: flex;
          padding: 12px 20px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          color: #fff;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 140%;

          transition: all 0.4s ease;

          @media (max-width: 767px) {
            width: 100%;
            padding: 10px 16px;
            font-size: 14px;
          }

          &:hover {
            background: #4985ff;
          }
        }
      }
    }
  }

  .policy {
    display: flex;
    gap: 8px;

    &._err {
      color: #ff3434;
    }

    input {
      width: 18px;
    }

    span {
    }

    div {
      display: inline;
      cursor: pointer;
      color: #4985ff;
    }
  }
}

@keyframes svgInit {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
