.button {
  color: #f8fafc;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  padding: 12px 18px;

  border-radius: 5px;
  background: #4985ff;

  transition: all 0.3s ease;

  @media (max-width: 991px) {
    padding: 10px 16px;
  }

  &:hover {
    background: #6196ff;
  }
}
