@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./assets/fonts/Inter-Bold.woff2') format('woff2'),
    url('./assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./assets/fonts/Inter-Light.woff2') format('woff2'),
    url('./assets/fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('./assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('./assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url('./assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
