.about {
  padding: 100px 0 0;

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }

  &Body {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;

    padding: 30px;
    border-radius: 12px;
    border: 1px solid #e5eaf1;
    background-color: rgba($color: #fff, $alpha: 0.7);
    backdrop-filter: blur(10px);

    @media (max-width: 900px) {
      flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    &__text {
      @media (max-width: 900px) {
        margin: 40px 0 0;
      }

      @media (max-width: 767px) {
        margin: 20px 0 0;
      }

      &Title {
        color: #323232;
        font-family: Montserrat;
        font-size: 36px;
        font-weight: 700;
        line-height: 130%;

        @media (max-width: 767px) {
          font-size: 24px;
        }
      }

      &Descr {
        margin: 40px 0 0;
        color: #999;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 158.47%;

        @media (max-width: 767px) {
          margin: 20px 0 0;
          font-size: 14px;
          line-height: 140%;
        }
      }
    }

    &__img {
      flex: 0 0 500px;
      margin: 0 0 0 120px;
      border-radius: 12px;
      box-shadow: 0px 0px 10px 0px rgba(111, 111, 111, 0.25);
      overflow: hidden;

      @media (max-width: 1200px) {
        flex: 0 0 400px;
        margin: 0 0 0 60px;
      }

      @media (max-width: 900px) {
        flex: 0 0 auto;
        margin: 0;
        width: 100%;
        height: 400px;
      }

      @media (max-width: 767px) {
        height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .con {
    display: flex;
    flex-direction: column;
  }
}
