.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 318px;
  padding: 30px 0;
  border-radius: 12px;
  border: 1px solid #e5eaf1;
  background-color: rgba($color: #fff, $alpha: 0.6);
  backdrop-filter: blur(10px);

  transition: all 0.4s ease;

  @media (max-width: 1200px) {
    flex: 0 0 calc(33.333% - 16px);
  }
  @media (max-width: 991px) {
    flex: 0 0 320px;
  }
  @media (max-width: 767px) {
    padding: 20px 0;

    flex: 0 0 auto;
    width: 100%;
    // max-width: 400px;
  }

  &:hover {
    background: rgba($color: #4985ff, $alpha: 1);

    .card {
      &__name,
      &__price,
      &__descr {
        color: #fff;
      }
      &__btn {
        color: #0f172a;
        background-color: #fff;
      }
    }
  }

  &__name {
    color: #0f172a;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.3px;
    padding: 0 20px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  &__price {
    margin: 20px 0 0;
    color: #4985ff;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 26px;
    padding: 0 20px;

    @media (max-width: 767px) {
      padding: 0 15px;
      font-size: 40px;
      margin: 14px 0 0;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      margin: 0 4px 0 0;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  &__descr {
    border-top: 1px solid #e5eaf1;
    margin: 20px 0 35px;
    padding: 15px 20px 0;
    color: #657790;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 767px) {
      margin: 14px 0 25px;
      padding: 15px 15px 0;
    }
  }
  &__btn {
    width: calc(100% - 40px);
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 18px 52px;
    margin: auto auto 0;

    border-radius: 5px;
    background: #4985ff;

    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.28px;

    transition: all 0.4s ease;

    @media (max-width: 767px) {
      width: calc(100% - 30px);
      padding: 14px;
      margin: auto auto 0;
    }

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.7) !important;
    }
  }
}
