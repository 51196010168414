.stats {
  display: inline-flex;
  margin: 90px auto 0;
  gap: 0 40px;
  justify-content: center;

  padding: 30px;
  border-radius: 12px;
  border: 1px solid #e5eaf1;
  background-color: rgba($color: #fff, $alpha: 0.7);
  backdrop-filter: blur(10px);

  @media (max-width: 991px) {
    margin: 50px auto 0;
  }

  @media (max-width: 767px) {
    width: auto;
    flex-wrap: wrap;
    gap: 30px;
  }
}
