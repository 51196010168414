.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  // border-bottom: 1px solid rgba($color: #a1a1a1, $alpha: 0.5);
  transition: all 0.3s ease;

  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);

  &.scrolled {
    background-color: rgba($color: #000, $alpha: 0.3);
    backdrop-filter: blur(7px);
  }

  &.hidden {
    margin: 0;
    transform: translate(0, -100%);
  }

  &Body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;

    @media (max-width: 991px) {
      padding: 15px 0;
    }

    @media (max-width: 767px) {
      padding: 10px 0;
    }

    &__logo {
    }

    &__menu {
      display: flex;
      align-items: center;
      gap: 0 40px;

      @media (max-width: 991px) {
        gap: 25px;
      }

      @media (max-width: 767px) {
        position: fixed;
        z-index: 9;
        top: 0;
        left: 0;
        right: 0;

        flex-direction: column;
        justify-content: center;

        height: 100vh;

        background-color: rgba(0, 0, 0, 0.9);
        backdrop-filter: blur(10px);

        transition: all 0.4s ease;
        opacity: 0;
        transform: translate(100%, 0);

        &.active {
          opacity: 1;
          transform: translate(0);
        }
      }

      &Item {
        position: relative;
        cursor: pointer;
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 130%;
        }

        @media (max-width: 767px) {
          font-size: 24px;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 1px;
          background-color: #fff;
          transition: all 0.3s ease;
        }

        &:hover {
          &::after {
            width: 100%;
            left: 0;
          }
        }
      }
    }
    &__btn {
    }

    &__burger {
      position: relative;
      width: 28px;
      height: 15px;

      &.active {
        span {
          &:nth-child(1) {
            top: 7px;
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            opacity: 0;
            transform: translate(100%, 0);
          }
        }
      }

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #fff;
        transition: all 0.4s ease;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }
  }
}
