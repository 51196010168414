.privacy {
  padding: 150px 0 0;

  background-color: rgba($color: #fff, $alpha: 0.3);
  backdrop-filter: blur(10px);

  h2 {
    font-size: 32px;
  }

  h3 {
    margin: 20px 0 0;
    font-size: 24px;
  }

  p {
    font-size: 20px;
    margin: 12px 0 0;
    line-height: 120%;
  }

  ul {
    margin: 20px 0 0;
    padding: 0 0 0 20px;

    li {
      margin: 20px 0 0;
    }
  }
}
