.services-slider {
  #services-slider-pagination {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;

    span {
      width: 10px;
      height: 10px;
      transition: all 0.4s ease;

      border: 2px solid #e5eaf1;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(10px);
      opacity: 1 !important;

      &.swiper-pagination-bullet-active {
        width: 26px;
        border-radius: 20px;
        background-color: #4985ff;
        border: 2px solid #4985ff;
      }
    }
  }
}
