.info {
  &__label {
    color: #4985ff;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 130%;
    }
  }
  &__title {
    margin: 16px 0 0;
    color: #0f172a;
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 66px;
    letter-spacing: -2px;
    text-align: center;

    @media (max-width: 767px) {
      margin: 4px 0 0;
      font-size: 36px;
      line-height: 130%;
    }
  }
}
