.footer {
  position: relative;
  margin: 130px 0 0;
  padding: 30px 0 50px;

  border-top: 1px solid #e5eaf1;

  background-color: rgba($color: #fff, $alpha: 0.7);
  backdrop-filter: blur(10px);

  @media (max-width: 767px) {
    margin: 70px 0 0;
  }

  &Body {
    display: flex;
    gap: 0 150px;

    @media (max-width: 1200px) {
      justify-content: space-between;
      gap: 0 40px;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
      gap: 40px 0;
    }
  }
  &Col {
    @media (max-width: 1200px) {
      flex: 0 0 auto;
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;

      &:nth-child(2) {
        flex: 0 0 50%;
      }
      &:nth-child(3) {
        flex: 0 0 50%;
      }
    }

    &__logo {
    }
    &__text {
      margin: 20px 0 0;
      max-width: 320px;
      color: #657790;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 135%;

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
    }
    &__cr {
      margin: 20px 0 0;

      color: #0f172a;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      span {
        color: #4985ff;
      }
    }

    &__privacy {
      cursor: pointer;
      margin: 8px 0 0;

      color: #0f172a;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      transition: all 0.3s ease;

      &:hover {
        color: #4985ff;
      }
    }

    &__title {
      color: #0f172a;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &__links {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 24px 0 0;

      &Item {
        cursor: pointer;
        color: #657790;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &Social {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 0 auto;

    @media (max-width: 991px) {
      width: 150px;
      gap: 10px;
      flex-wrap: wrap;
    }

    @media (max-width: 340px) {
      width: 130px;
    }

    & > span {
      color: #0f172a;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      background-color: #f6f6f8;
      border-radius: 5px;
      transition: all 0.4s ease;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: #657791;
          transition: all 0.4s ease;
        }
      }

      &:hover {
        background: #4985ff;

        svg {
          path {
            fill: #fff;
          }
        }
      }

      &:last-child {
        svg {
          path {
            stroke: #657791;
          }
        }
        &:hover {
          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}
