.services {
  padding: 100px 0 0;
  margin: 20px 0 0;

  @media (max-width: 767px) {
    padding: 50px 0 0;
    margin: 0;
  }

  &Body {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin: 54px 0 0;

    @media (max-width: 991px) {
      margin: 32px 0 0;
      flex-wrap: wrap;
    }
  }

  &Slider {
    margin: 32px 0 0;
    overflow: visible !important;
  }
}
