.main {
  position: relative;
  background-image: url(./assets/img.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: 991px) {
    background-attachment: unset;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  &Body {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (max-width: 767px) {
      height: auto;
      padding: 175px 0;
    }

    &__label {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;

      opacity: 0.7;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 110%;
      }
    }

    &__title {
      margin: 16px 0 0;
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 48px;
      font-weight: 700;
      line-height: 134.09%;

      @media (max-width: 767px) {
        margin: 14px 0 0;
        font-size: 32px;
        line-height: 120%;
      }

      @media (max-width: 350px) {
        font-size: 28px;
      }
    }

    &__btn {
      margin: 50px 0 0;

      @media (max-width: 767px) {
        margin: 20px 0 0;
      }
    }
  }
}
