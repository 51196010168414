.logo {
  display: flex;
  align-items: center;

  pointer-events: none;
  user-select: none;

  &.black {
    .logoText {
      color: #000;
    }
  }

  &__img {
    flex: 0 0 auto;
    width: 38px;

    @media (max-width: 991px) {
      width: 28px;
    }

    @media (max-width: 767px) {
      width: 38px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &Text {
    height: 100%;
    position: relative;
    margin: 0 0 0 8px;
    color: #fff;
    overflow: hidden;

    @media (max-width: 991px) {
      margin: 0 0 0 7px;
    }

    @media (max-width: 767px) {
      margin: 0 0 0 8px;
    }

    &__big {
    }

    &__hidden {
      opacity: 0;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 0 0 5px;
      position: absolute;
      top: 0;
    }

    &__small {
      font-size: 28px;
      font-family: Inter;
      font-weight: 300;
    }

    &__title {
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;

      @media (max-width: 991px) {
        font-size: 14px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
    &__label {
      margin: 3px 0 0;
      font-family: Inter;
      font-size: 8px;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 3.28px;

      @media (max-width: 991px) {
        font-size: 7px;
        letter-spacing: 2.3px;
      }

      @media (max-width: 767px) {
        font-size: 8px;
        letter-spacing: 3.28px;
      }
    }
  }
}
