.card {
  position: relative;
  flex: 0 0 calc(33.333%);
  padding: 0 40px;

  @media (max-width: 991px) {
    padding: 0 25px;
  }

  @media (max-width: 767px) {
    padding: 25px;
  }

  &:not(:first-child) {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #e5eaf1;

      @media (max-width: 767px) {
        width: calc(100% - 40px);
        left: 20px;
        height: 1px;
      }
    }
  }

  &__icon {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background-color: rgba($color: #4985ff, $alpha: 0.1);
      border-radius: 50%;

      @media (max-width: 991px) {
        width: 52px;
        height: 52px;
      }

      img {
        width: 36px;
        height: 36px;
        object-fit: contain;

        @media (max-width: 991px) {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  &__title {
    margin: 30px 0 0;
    color: #0f172a;

    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -1px;

    @media (max-width: 991px) {
      margin: 20px 0 0;
      font-size: 20px;
    }
  }
  &__descr {
    margin: 20px 0 0;

    color: #657790;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;

    @media (max-width: 991px) {
      margin: 10px 0 0;
      font-size: 14px;
      line-height: 150%;
    }
  }
}
