.portfolio {
  padding: 80px 0 0;
  margin: 40px 0 0;

  @media (max-width: 767px) {
    padding: 50px 0 0;
    margin: 0;
  }

  .con {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1500px;
  }

  &Body {
    width: 100%;
    margin: 54px 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 767px) {
      margin: 32px 0 0;
      gap: 16px;
    }

    &__card {
    }
  }

  &Bottom {
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }

    &__clear {
      position: relative;
      z-index: 1;
      cursor: pointer;
      color: #000;
      font-size: 18px;
      transition: all 0.3s ease;
      margin: 0 20px 0 0;

      @media (max-width: 500px) {
        margin: -10px 0 0;
      }

      &:hover {
        color: #4985ff;
      }
    }

    &__btn {
      margin: 0 auto;
    }
  }
}
