.why {
  padding: 100px 0 0;
  margin: 20px 0 0;

  @media (max-width: 767px) {
    padding: 50px 0 0;
    margin: 0;
  }

  &__title {
  }

  &Body {
    display: flex;
    justify-content: center;
    margin: 54px auto 0;
    padding: 50px 0;
    max-width: 1220px;
    border-radius: 12px;
    border: 1px solid #e5eaf1;
    background-color: rgba($color: #fff, $alpha: 0.8);
    backdrop-filter: blur(7px);

    @media (max-width: 991px) {
      padding: 30px 0;
    }
    @media (max-width: 767px) {
      margin: 32px auto 0;
      flex-direction: column;
      padding: 0;
    }
  }
}
